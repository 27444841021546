import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { ToasterService } from '../toaster.service';
import {ApiService} from "../services/api.service";

interface ChangeData {
  email: string;
  old: string;
  new: string;
  forgot: boolean;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: ApiService,
    private loader: SpinnerService,
    private toaster: ToasterService,
    private router: Router,
    private authService: AuthService
  ) {}
  confirm = '';
  forgot = false;
  data: ChangeData = { email: '', forgot: false, new: '', old: '' };
  ngOnInit() {
    const email = this.route.snapshot.queryParams['email'];
    const key = this.route.snapshot.queryParams['key'];
    if (email && key) {
      this.forgot = true;
      this.data.email = email;
      this.data.old = key;
      this.data.forgot = true;
    }
  }

  changePassword() {
    this.loader.show();
    this.http.post('/api/changePassword', this.data).subscribe(
      data => {
        this.loader.hide();
        if (data['success']) {
          this.toaster.success('Success', 'Password Changed');
          this.authService.logout();
          this.router.navigate(['/']);
        } else {
          this.toaster.error('Error', data['error']);
        }
      },
      error => {
        this.loader.hide();
        this.toaster.error('Error', error);
      }
    );
  }
}
