import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanyService} from '../company.service';
import {ActivatedRoute} from '@angular/router';
import {UserManagementService} from '../../user-management.service';
import {NgForm} from '@angular/forms';
import {ToasterService} from 'src/app/toaster.service';
import {SpinnerService} from 'src/app/spinner/spinner.service';
import {BreadcrumbsService} from 'ng6-breadcrumbs';
import {LicenseService} from '../../../common/license.service';
import {error} from 'util';
import {toBase64String} from "@angular/compiler/src/output/source_map";

interface User {
    name: string;
    phone: number;
    email: string;
    password: string;
    jobTitle: string;
    id: number;
}

interface LicenseData {
    companyName: string;
    macAddress: string;
    deviceCount: number;
    monitoringMode: boolean;
    trialMode: boolean;
    time: number;
    tabs: {
        multiview: boolean;
        videoWall: boolean;
        controlUI: boolean;
        scheduler: boolean;
        analytics: boolean;
        presenter: boolean;
        anyControl: boolean;
    };
}

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    providers: [LicenseService]
})
export class CompanyComponent implements OnInit {
    initCompanyData: any;
    companyData: any;
    initFeatures: any;
    initEditLicense: any;
    features: any;
    deleteLicense: any;
    id: number;
    users: any = [];
    selectedUser: User = {
        name: '',
        phone: null,
        email: '',
        password: '',
        jobTitle: '',
        id: null
    };
    modalSection = 'add';
    numLicenses = 0;
    licenseData: any;
    newLicense: any = {};
    view = false;
    typeHardware = false;
    generateNewLicense = true;
    selectedLicenseId: Number;
    limitDate: any;
    minDate = new Date();
    @ViewChild('userForm') userForm: NgForm;

    constructor(private companyService: CompanyService,
                private route: ActivatedRoute,
                private userService: UserManagementService,
                private toaster: ToasterService,
                private loader: SpinnerService,
                private breadcrumbs: BreadcrumbsService,
                private licenseService: LicenseService) {
    }

    ngOnInit() {
        this.id = +this.route.snapshot.params['id'];
        this.loadData();
        this.resetLicense();
    }

    resetLicense() {
        this.newLicense = {
            companyName: '',
            customerName: '',
            macAddress: '',
            deviceCount: 0,
            monitoringMode: false,
            trialMode: false,
            tabs: {
                multiview: false,
                videoWall: false,
                controlUI: false,
                scheduler: false,
                analytics: false,
                presenter: false,
                anyControl: false
            },
            time: 0
        };
    }

    loadData() {
        this.loader.show();
        this.companyService.getCompanyData(this.id).subscribe(
            data => {
                this.loader.hide();
                this.companyData = data;
                this.initCompanyData = Object.assign({}, data);
                this.initFeatures = JSON.parse(this.initCompanyData['features']);
                this.features = Object.assign({}, this.initFeatures);
                this.typeHardware = this.companyData['partner_type'] == 'Hardware';
                this.breadcrumbs.store([
                    {label: 'Home', url: '/admin/', params: []},
                    {label: 'Companies', url: '/admin/companies', params: []},
                    {
                        label: this.initCompanyData.name,
                        url: '/admin/companies/' + this.id,
                        params: []
                    }
                ]);
            },
            error => this.loader.hide()
        );
    }

    resetOptions() {
        this.newLicense.tabs = {
            multiview: false,
            videoWall: false,
            controlUI: false,
            scheduler: false,
            analytics: false,
            presenter: false,
            anyControl: false
        }
    }

    changeJson(key) {
        this.features[key] = this.features[key]===true ? 1 : 0;
        this.companyData.features = JSON.stringify(this.features);
    }

    disabledSave() {
        return (JSON.stringify(this.companyData) === JSON.stringify(this.initCompanyData)) ||
            (this.companyData.partner_type==='Software' && !this.features['multiview'] && !this.features['videoWall'] && !this.features['controlUI'] && !this.features['scheduler'] &&
            !this.features['analytics'] && !this.features['presenter'] && !this.features['anyControl']);
    }

    generateLicense() {
        try{
            let regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

            if(regex.test(atob(this.newLicense.macAddress))){
                this.loader.show();
                this.licenseService
                    .generateKey(
                        {data: JSON.stringify(this.newLicense), edit: false},
                        !this.typeHardware,
                        this.id
                    )
                    .subscribe(
                        data => {
                            this.loader.hide();
                            if (data['success']) {
                                this.toaster.success('Success', 'License generated');
                                ($('#licenseModal') as any).modal('hide');
                                this.getLicenses(false);
                                this.loadData();
                            } else {
                                this.toaster.error('Error', data['error']);
                            }
                        },
                        error => {
                            this.loader.hide();
                            this.toaster.error('Error', error);
                        }
                    );
            }else{
                this.toaster.error('Invalid Serial Number');
            }
        }catch  (e){
            this.toaster.error('Invalid Serial Number');
        }

    }

    editSoftwareLicense() {
        try{
            let regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

            if(regex.test(atob(this.newLicense.macAddress))){
                if(this.initEditLicense['device_count']<=this.newLicense.deviceCount) {
                    this.loader.show();
                    this.licenseService
                        .editKey(
                            {data: JSON.stringify(this.newLicense), edit: true},
                            this.id
                        )
                        .subscribe(
                            data => {
                                this.loader.hide();
                                if (data['success']) {
                                    this.toaster.success('Success', 'License edited');
                                    ($('#licenseModal') as any).modal('hide');
                                    this.getLicenses(false);
                                    this.loadData();
                                } else {
                                    this.toaster.error('Error', data['error']);
                                }
                            },
                            error => {
                                this.loader.hide();
                                this.toaster.error('Error', error);
                            }
                        );
                }else{
                    this.toaster.error('Device count should not be less than old device count.');
                }
            }else{
                this.toaster.error('Invalid Serial Number');
            }
        }catch  (e){
            this.toaster.error('Invalid Serial Number');
        }
    }

    getLicenses(requested) {
        this.licenseData = [];
        this.loader.show();
        this.licenseService.getLicenses(this.id, true).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    if(this.companyData.partnerType === 'SOFTWARE') {
                        this.licenseData = data['licenses'];
                    }else{
                        for(let license of data['licenses']){
                            if(requested){
                                if(license['action_type']=='REQUEST') {
                                    this.licenseData.push(license)
                                }
                            }else{
                                if(license['action_type']!='REQUEST'){
                                    this.licenseData.push(license)
                                }
                            }
                        }
                    }
                } else {
                    this.toaster.error('Error', data['error']);
                }
            },
            error => this.loader.hide()
        );
    }

    copyToClipboard(text) {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.toaster.success('Success', 'Copied to clipboard');
    }

    editSWLicenseKey(macAddress) {
        for (let license of this.licenseData) {
            if (license['unique_id'] === macAddress) {
                let info = JSON.parse(license.license_info);
                let tabs = info.tabs;
                this.initEditLicense = license;
                this.generateNewLicense = false;
                this.newLicense = {
                    companyName: license['customer_name'],
                    customerName: license['customer_name'],
                    macAddress: license['unique_id'],
                    deviceCount: license['device_count'],
                    monitoringMode: license['license_type'] == "HARDWARE"? info['monitoringMode'] : false,
                    trialMode: license['license_type'] == "HARDWARE"? info['trialMode'] : false,
                    tabs: {
                        multiview: this.typeHardware ? tabs.multiview : (this.initFeatures['multiview'] == 1 ? true : false),
                        videoWall: this.typeHardware ? tabs.videoWall : (this.initFeatures['videoWall'] == 1 ? true : false),
                        controlUI: this.typeHardware ? tabs.controlUI : (this.initFeatures['controlUI'] == 1 ? true : false),
                        scheduler: this.typeHardware ? tabs.scheduler : (this.initFeatures['scheduler'] == 1 ? true : false),
                        analytics: this.typeHardware ? tabs.analytics : (this.initFeatures['analytics'] == 1 ? true : false),
                        presenter: this.typeHardware ? tabs.presenter : (this.initFeatures['presenter'] == 1 ? true : false),
                        anyControl: this.typeHardware ? tabs.anyControl : (this.initFeatures['anyControl'] == 1 ? true : false)
                    },
                    time: new Date().getTime(),
                    id: license['id']
                };
                ($('#licenseModal') as any).modal('show');
            }
        }
    }

    viewLicense(id){
        this.licenseService.viewLicense(id).subscribe(
            data => {
                if(data['licenseInfo']!=null){
                    this.newLicense = JSON.parse(data['licenseInfo']);
                    ($('#licenseModal') as any).modal('show');
                    this.view = true;
                }
            },
            error => this.loader.hide()
        );
    }

    generateNew() {
        this.generateNewLicense = true;
        let features = JSON.parse(this.initCompanyData.features);
        this.newLicense = {
            companyName: this.initCompanyData && this.initCompanyData.name ? this.initCompanyData.name : '',
            customerName: '',
            macAddress: '',
            deviceCount: 2,
            monitoringMode: false,
            trialMode: false,
            tabs: {
                multiview: features.multiview,
                videoWall: features.videoWall,
                controlUI: features.controlUI,
                scheduler: features.scheduler,
                analytics: features.analytics,
                presenter: features.presenter,
                anyControl: features.anyControl
            },
            time: new Date().getTime()
        };
        ($('#licenseModal') as any).modal('show');
    }

    limitUpdate(license) {
        if (license.expiry > this.minDate) {
            this.licenseService
                .limitUpdate(license.id, {date: license.expiry.toDateString()})
                .subscribe(
                    data => {
                        this.loader.hide();
                        if (data['success']) {
                            this.loadData();
                            this.toaster.success('Success', 'Update limited');
                        } else {
                            this.toaster.error('Error', data['error'] ? data['error'] : '');
                        }
                    },
                    error => this.loader.hide()
                );
        } else {
            this.toaster.error('Error', 'The date entered must be a future date.');
        }
    }

    limitUpdates(license) {
        this.selectedLicenseId = license.id;
        ($('#limitLicenseModal') as any).modal('show');
    }

    releaseLicense(license) {
        this.newLicense = license['license_key'];
        this.selectedLicenseId = license.id;
        this.generateNewLicense = false;
        ($('#licenseModal') as any).modal('show');
    }

    updateCompanyData() {
        this.loader.show();
        this.companyService.updateCompanyData(this.companyData).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    this.loadData();
                    this.toaster.success('Success', 'Company updated');
                } else {
                    this.toaster.error('Error', data['error'] ? data['error'] : '');
                }
            },
            error => this.loader.hide()
        );
    }

    getCompanyUsers() {
        this.loader.show();
        this.userService.getCompanyUsers(this.id).subscribe(
            data => {
                this.loader.hide();
                if (data['error']) {
                    this.toaster.error('Error', data['error']);
                } else {
                    this.users = data;
                }
            },
            error => this.loader.hide()
        );
    }

    addUser() {
        this.loader.show();
        this.userService.addCompanyUser(this.id, this.selectedUser).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    this.toaster.success('User Added');
                    this.getCompanyUsers();
                } else {
                    this.toaster.error('Error', data['error']);
                }
            },
            error => this.loader.hide()
        );
    }

    editUser(user) {
        this.selectedUser = {
            name: user.name,
            email: user.email,
            jobTitle: user.job_title,
            phone: user.phone,
            password: '',
            id: user.id
        };
        this.modalSection = 'edit';
    }

    updateUser() {
        this.loader.show();
        this.userService.updateCompanyUser(this.selectedUser).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    this.toaster.success('User Updated');
                    this.getCompanyUsers();
                } else {
                    this.toaster.error('Error', data['error']);
                }
            },
            error => this.loader.hide()
        );
    }

    disabledGenerateLicense() {
        return this.newLicense!=undefined && (!this.newLicense.macAddress || !this.newLicense.companyName || this.newLicense.deviceCount < 2 ||
            (this.typeHardware && !this.newLicense.monitoringMode && !this.newLicense.tabs.multiview && !this.newLicense.tabs.videoWall && !this.newLicense.tabs.controlUI && !this.newLicense.tabs.scheduler && !this.newLicense.tabs.analytics && !this.newLicense.tabs.presenter && !this.newLicense.tabs.anyControl));
    }

    deleteUser(id: number) {
        this.loader.show();
        this.userService.deleteCompanyUser(id).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    this.toaster.success('User Deleted');
                    this.getCompanyUsers();
                } else {
                    this.toaster.error('Error', data['error']);
                }
            },
            error => this.loader.hide()
        );
    }

    selectAddUser() {
        this.selectedUser = {
            name: '',
            email: '',
            jobTitle: '',
            phone: null,
            password: '',
            id: null
        };
        this.modalSection = 'add';
        this.userForm.reset();
    }

    addLicenses() {
        this.loader.show();
        this.companyService.addLicenseCount(this.id, this.numLicenses).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    this.loadData();
                    this.numLicenses = 0;
                    this.toaster.success('Licenses added');
                } else {
                    this.toaster.error('Error', data['error']);
                }
            },
            error => this.loader.hide()
        );
    }

    openEditPanel() {
        this.companyData = Object.assign({}, this.initCompanyData);
        this.features = Object.assign({}, this.initFeatures);
    }
}
