import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ApiService} from "../services/api.service";

@Injectable()
export class UserManagementService {
  constructor(private http: ApiService) {}

  getPendingRequests() {
    return this.http.get('/api/getPending');
  }

  getActiveRequests() {
    return this.http.get('/api/getActive');
  }

  getDenyRequests(){
    return this.http.get('/api/getDenied');
  }


  activateUser(id, access) {
    return this.http.post('/api/activateUser/' + id, access);
  }

  deactivateUser(id) {
    return this.http.get('/api/deactivateUser/' + id);
  }

  denyUser(id) {
      return this.http.get('/api/denyUser/' + id);
  }

  getCompanyUsers(id) {
    return this.http.get('/api/companyUsers/' + id);
  }

  addCompanyUser(id, user) {
    return this.http.post('/api/addCompanyUser/' + id, user);
  }

  updateCompanyUser(user) {
    return this.http.post('/api/updateCompanyUser', user);
  }

  deleteCompanyUser(id) {
    return this.http.get('/api/deleteCompanyUser/' + id);
  }

  //staff Users
  getStaffUsers() {
    return this.http.get('/api/staffUsers');
  }

  addStaffUser(user) {
    return this.http.post('/api/addStaffUser' , user);
  }

  updateStaffUser(user) {
    return this.http.post('/api/updateStaffUser', user);
  }

  deleteStaffUser(id) {
    return this.http.get('/api/deleteStaffUser/' + id);
  }
}
