import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationComponent } from './registration/registration.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminComponent } from './admin/admin.component';
import { RequestsComponent } from './admin/requests/requests.component';
import { CompaniesComponent } from './admin/companies/companies.component';
import { CompanyComponent } from './admin/companies/company/company.component';
import { CustomerComponent } from './customer/customer.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/auth.service';
import { ManualsComponent } from './customer/manuals/manuals.component';
import { SoftwareComponent } from './customer/software/software.component';
import { UsersComponent } from './customer/users/users.component';
import { LicensesComponent } from './customer/licenses/licenses.component';
import { AdminGuard } from './auth/admin-guard.service';
import { CustomerGuard } from './auth/customer-guard.service';
import { CustomerAdminGuard } from './auth/customer-admin-guard.service';
import { HeaderComponent } from './header/header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerModule } from './spinner/spinner.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordGuard } from './change-password/change-password-guard.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BreadcrumbsModule } from 'ng6-breadcrumbs';
import { HomeComponent } from './admin/home/home.component';
import { FooterComponent } from './footer/footer.component';
import { CustomerHomeComponent } from './customer/customer-home/customer-home.component';
import { StaffUsersComponent } from './admin/staff-users/staff-users.component';
import * as $ from 'jquery';
import { StaffGuard } from './auth/staff-guard.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule, DateAdapter
} from '@angular/material';
import {EnvServiceProvider} from "./services/env.service.provider";


@NgModule({
    declarations: [
        AppComponent,
        RegistrationComponent,
        LoginComponent,
        RequestsComponent,
        AdminComponent,
        CompaniesComponent,
        CompanyComponent,
        CustomerComponent,
        ManualsComponent,
        SoftwareComponent,
        UsersComponent,
        LicensesComponent,
        HeaderComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        HomeComponent,
        FooterComponent,
        CustomerHomeComponent,
        StaffUsersComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        FormsModule,
        HttpClientModule,
        BreadcrumbsModule,
        SpinnerModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        NoopAnimationsModule
    ],
    providers: [
        AuthService,
        AdminGuard,
        StaffGuard,
        EnvServiceProvider,
        CustomerGuard,
        CustomerAdminGuard,
        ChangePasswordGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private dateAdapter: DateAdapter<any>){
        this.dateAdapter.setLocale('en-in');
    }
}
