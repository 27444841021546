import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'src/app/toaster.service';
import { NgForm } from '@angular/forms';
import { SpinnerService } from 'src/app/spinner/spinner.service';
import {UserManagementService} from "../user-management.service";

interface User {
  name: string;
  phone: number;
  email: string;
  password: string;
  jobTitle: string;
  id: number;
}

@Component({
  selector: 'app-staff-users',
  templateUrl: './staff-users.component.html',
  styleUrls: ['./staff-users.component.scss']
})
export class StaffUsersComponent implements OnInit {

  id: number;
  users: any = [];
  selectedUser: User = {
    name: '',
    phone: null,
    email: '',
    password: '',
    jobTitle: '',
    id: null
  };

  @ViewChild('userForm') userForm: NgForm;

  modalSection = 'add';

  constructor(
    private userService: UserManagementService,
    private toasterService: ToasterService,
    private loader: SpinnerService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.loader.show();
    this.userService.getStaffUsers().subscribe(
      data => {
        this.loader.hide();
        if (data['error']) {
          this.toasterService.error('Error', 'unable to fetch users');
        } else {
          this.users = data;
        }
      },
      error => this.loader.hide()
    );
  }

  addUser() {
    this.userService.addStaffUser(this.selectedUser).subscribe(data => {
      if (data['success']) {
        this.getUsers();
        this.toasterService.success('User Added');
      } else {
        this.toasterService.error('Error', data['error']);
      }
    });
  }

  editUser(user) {
    this.selectedUser = {
      name: user.name,
      email: user.email,
      jobTitle: user.job_title,
      phone: user.phone,
      password: '',
      id: user.id
    };
    this.modalSection = 'edit';
  }

  updateUser() {
    this.userService.updateStaffUser(this.selectedUser).subscribe(data => {
      if (data['success']) {
        this.getUsers();
        this.toasterService.success('User Updated');
      } else {
        this.toasterService.error('Error', data['error']);
      }
    });
  }

  deleteUser(id: number) {
    this.userService.deleteStaffUser(id).subscribe(data => {
      if (data['success']) {
        this.getUsers();
        this.toasterService.success('User Deleted');
      } else {
        this.toasterService.error('Error', data['error']);
      }
    });
  }

  selectAddUser() {
    this.selectedUser = {
      name: '',
      email: '',
      jobTitle: '',
      phone: null,
      password: '',
      id: null
    };
    this.modalSection = 'add';
    this.userForm.reset();
  }
}
