import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '../toaster.service';
import { SpinnerService } from '../spinner/spinner.service';
import {ApiService} from "../services/api.service";
/**
 * Created by Rajat on 07-Jan-19.
 */
@Injectable()
export class AuthService {

    isAuthenticated = false;
    userType = '';
    name = '';

    constructor(
        private http: ApiService,
        private router: Router,
        private toaster: ToasterService,
        private loader: SpinnerService
    ) {}


    checkLogin(redirect){
        return new Promise(resolve=> {
            this.http
                .get('/api/user').subscribe(ob => {
                this.setCurrentUser(ob, redirect);
                resolve();
            },error=>{
                  resolve();
            });
        });
    }

    setCurrentUser(data,redirect){
        if (data['result'] === 'invalid') {
            this.isAuthenticated = false;
           // this.toaster.error('Invalid credentials');
        } else {
            this.isAuthenticated = true;
            this.userType = data['user'];
            this.name = data['name'];
            if(redirect) {
                if (data['user'] === 'ADMIN' || data['user'] === 'STAFF') {
                    this.router.navigate(['/admin']);
                } else {
                    this.router.navigate(['/customer', 'manuals']);
                }
            }
        }
    }

    login(user: { email: string; password: string }) {
        this.loader.show();
        this.http
            .post('/api/login2', {
                Authorization: 'Basic ' + btoa(user.email + ':' + user.password)
            })
            .subscribe(
                data => {
                    if (data['result'] === 'valid') {
                        this.loader.hide();
                        this.setCurrentUser(data,true);
                    } else {
                        this.loader.hide();
                        this.toaster.error('Invalid credentials');
                    }
                },
                error => {
                    console.log(error);
                    this.loader.hide();
                }
            );
    }

    logout() {
        this.isAuthenticated = false;
        this.userType = '';
        return this.http.get('/api/logout');
    }

    isLoggedIn() {
        return this.isAuthenticated;
    }

    isAdmin() {
        const promise = new Promise((resolve, reject) => {
            if (this.isLoggedIn()) {
                resolve(this.userType === 'ADMIN');
            } else {
                this.http.get('/api/user').subscribe(
                    data => {
                        if (data['result'] === 'invalid') {
                            this.isAuthenticated = false;
                            resolve(false);
                        } else {
                            this.isAuthenticated = true;
                            this.userType = data['user'];
                            this.name = data['name'];
                            resolve(this.userType === 'ADMIN');
                        }
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    }
                );
            }
        });
        return promise;
    }

    isStaff() {     //values accessible to staff are accessible to admin too
        const promise = new Promise((resolve, reject) => {
            if (this.isLoggedIn()) {
                resolve(this.userType === 'STAFF' || this.userType === 'ADMIN');
            } else {
                this.http.get('/api/user').subscribe(
                    data => {
                        if (data['result'] === 'invalid') {
                            this.isAuthenticated = false;
                            resolve(false);
                        } else {
                            this.isAuthenticated = true;
                            this.userType = data['user'];
                            this.name = data['name'];
                            resolve(this.userType === 'ADMIN' || this.userType === 'STAFF');
                        }
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    }
                );
            }
        });
        return promise;
    }

    isCustomerAdmin() {
        const promise = new Promise((resolve, reject) => {
            if (this.isLoggedIn()) {
                resolve(this.userType === 'CUSTOMER_ADMIN');
            } else {
                this.http.get('/api/user').subscribe(
                    data => {
                        if (data['result'] === 'invalid') {
                            this.isAuthenticated = false;
                            resolve(false);
                        } else {
                            this.isAuthenticated = true;
                            this.userType = data['user'];
                            this.name = data['name'];
                            resolve(this.userType === 'CUSTOMER_ADMIN');
                        }
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    }
                );
            }
        });
        return promise;
    }

    isCustomer() {
        const promise = new Promise((resolve, reject) => {
            if (this.isLoggedIn()) {
                resolve(
                    this.userType === 'CUSTOMER' || this.userType === 'CUSTOMER_ADMIN'
                );
            } else {
                this.http.get('/api/user').subscribe(
                    data => {
                        if (data['result'] === 'invalid') {
                            this.isAuthenticated = false;
                            resolve(false);
                        } else {
                            this.isAuthenticated = true;
                            this.userType = data['user'];
                            this.name = data['name'];
                            resolve(
                                this.userType === 'CUSTOMER' ||
                                this.userType === 'CUSTOMER_ADMIN'
                            );
                        }
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    }
                );
            }
        });
        return promise;
    }

    getUsername() {
        return this.name;
    }
}
