import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management.service';
import { ToasterService } from 'src/app/toaster.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';
import { CompanyService } from '../companies/company.service';

interface Accessibility {
  partnerType: string;
  softwareLicenseData: {
      multiview: false,
      videoWall: false,
      controlUI: false,
      scheduler: false,
      analytics: false,
      presenter: false,
      anyControl: false
  }
}
declare var $: any;
@Component({
  selector: 'app-user-management',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  companies: any = [];
  company: any;
  selectedId: any;
  action: any;
  pendingSection = true;
  denySection = false;
  access: Accessibility = { partnerType: '', softwareLicenseData: {
      multiview: false,
      videoWall: false,
      controlUI: false,
      scheduler: false,
      analytics: false,
      presenter: false,
      anyControl: false
    }
  };
  constructor(
    private userManagementService: UserManagementService,
    private toasterService: ToasterService,
    private companyService: CompanyService,
    private loader: SpinnerService
  ) {}

  ngOnInit() {
    this.loadPendingRequests();
    // $('#requestsTable').DataTable();
  }

  resetOptions() {
      this.access.softwareLicenseData = {
          multiview: false,
          videoWall: false,
          controlUI: false,
          scheduler: false,
          analytics: false,
          presenter: false,
          anyControl: false
      }
  }
  loadPendingRequests() {
    this.companies = [];
    this.access.softwareLicenseData = {
        multiview: false,
        videoWall: false,
        controlUI: false,
        scheduler: false,
        analytics: false,
        presenter: false,
        anyControl: false
    }
    this.loader.show();
    this.userManagementService.getPendingRequests().subscribe(
      data => {
        this.loader.hide();
        this.pendingSection = true;
        this.denySection = false;
        this.companies = data;
      },
      error => {
        console.log(error);
        this.loader.hide();
      }
    );
  }

  loadActiveRequests() {
    this.loader.show();
    this.companies = [];
    this.userManagementService.getActiveRequests().subscribe(
      data => {
        this.loader.hide();
        this.pendingSection = false;
        this.denySection = false;
        this.companies = data;
      },
      error => {
        this.loader.hide();
        console.log(error);
      }
    );
  }

  loadDenyRequests() {
    this.loader.show();
    this.companies = [];
    this.pendingSection = false;
    this.userManagementService.getDenyRequests().subscribe(
        data => {
            this.loader.hide();
            this.denySection = true;
            this.companies = data;
        },
        error => {
            this.loader.hide();
            this.denySection = true;
            console.log(error);
        }
    );
  }

  openModal(id, action) {
      this.action = action;
      this.selectedId = id;
      ($('#confirmModal') as any).modal('show');
  }

  view(company) {
    this.company = company;
  }

  activate(company) {
    this.company = company;
  }

  activateUser(id) {
    this.loader.show();
    this.userManagementService.activateUser(id, this.access).subscribe(
      data => {
        this.loader.hide();
        if (data['success']) {
          this.loadPendingRequests();
          this.toasterService.success('Success', 'Activated');
        } else {
          this.toasterService.error('Error', data['error']);
        }
      },
      error => {
        this.loader.hide();
        this.toasterService.error('Error', error);
      }
    );
  }

  deactivateUser() {
    this.loader.show();
    this.userManagementService.deactivateUser(this.selectedId).subscribe(
      data => {
        this.loader.hide();
        this.selectedId = '';
        this.action = '';
        if (data['success']) {
            if(this.denySection){
                this.loadDenyRequests();
            }else{
                this.loadActiveRequests();
            }
          this.toasterService.success('Success', 'Deactivated');
        } else {
          this.toasterService.error('Error', data['error']);
        }
      },
      error => {
        this.loader.hide();
        this.toasterService.error('Error', error);
      }
    );
  }

  denyUser(id) {
    this.loader.show();
    this.userManagementService.denyUser(id).subscribe(
        data => {
            this.loader.hide();
            if (data['success']) {
                this.loadPendingRequests();
                this.toasterService.success('Success', 'Denied');
            } else {
                this.toasterService.error('Error', data['error']);
            }
        },
        error => {
            this.loader.hide();
            this.toasterService.error('Error', error);
        }
    );
  }


  delete() {
      this.companyService.deleteCompany(this.selectedId).subscribe(data => {
          this.selectedId = '';
          if (data['success']) {
              this.toasterService.success('User Deleted');
          } else {
              this.toasterService.error('Error', data['error']);
          }
          this.loadDenyRequests();
      });
  }
}
