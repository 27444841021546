import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name = '';
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.name = this.authService.getUsername();
  }
  logout() {
    this.authService.logout().subscribe(data => {
      if (data['result'] === 'true') {
        this.router.navigate(['']);
      }
    });
  }

  changePassword(){
    if (this.authService.isCustomer().then(
      data => {
        if (data){
          this.router.navigate(['/customer','change-password']);
        }else{
          this.router.navigate(['/admin','change-password']);
        }
      }
      )){

    }
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
