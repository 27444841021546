
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    display = false;
    user: { email: string; password: string } = { email: '', password: '' };

    constructor(private authService: AuthService,private router: Router) {}

    ngOnInit() {

        this.authService.checkLogin(true).then(r=>{
            setTimeout(()=> {
                this.display = true;
            },1000);
        });

     /*   if(this.authService.isLoggedIn()){
            if(this.authService.isAdmin()){
                this.router.navigate(['/admin']);
            }else if(this.authService.isCustomer()){
                this.router.navigate(['/customer']);
            }else if(this.authService.isCustomerAdmin()){
                this.router.navigate(['/customer']);
            }else if(this.authService.isStaff()){
                this.router.navigate(['/admin']);
            }
        }*/

    }

    login() {
        this.authService.login(this.user);
    }
}
