import { Component } from '@angular/core';
import {ApiService} from "./services/api.service";
import {AuthService} from "./auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  constructor(private authService:AuthService){
      this.authService.checkLogin(false);
  }
}
