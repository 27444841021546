import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseService } from '../common/license.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [LicenseService]
})
export class CustomerComponent implements OnInit {
  isAdmin = false;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.isCustomerAdmin();
  }
  logout() {
    this.authService.logout().subscribe(data => {
      if (data['result'] === 'true') {
        this.router.navigate(['']);
      }
    });
  }

  isCustomerAdmin() {
    return this.authService
      .isCustomerAdmin()
      .then((isCustomerAdmin: boolean) => {
        this.isAdmin = isCustomerAdmin;
      });
  }
}
