import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SpinnerService} from "../../spinner/spinner.service";
import {ToasterService} from "../../toaster.service";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-customer-home',
  templateUrl: './customer-home.component.html',
  styleUrls: ['./customer-home.component.scss']
})
export class CustomerHomeComponent implements OnInit {
  licensesBalance = 0;
  licensesPurchased = 0;
  users = 0;

  constructor(private http: ApiService, private toaster: ToasterService, private loader: SpinnerService) {
  }

  ngOnInit() {
    this.loader.show();
    this.http.get('/api/getHomeData').subscribe(
      data => {
        this.loader.hide();
        if (data['success']) {
          this.licensesPurchased = data['licensesPurchased'];
          this.licensesBalance = data['licensesBalance'];
          this.users = data['users'];
        } else {
          this.toaster.error('Error', data['error']);
        }
      },
      error => {
        this.loader.hide();
        this.toaster.error('Error', error);
      }
    );
  }

}
