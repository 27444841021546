import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from './company.service';
import { SpinnerService } from 'src/app/spinner/spinner.service';
import { ToasterService } from 'src/app/toaster.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  id: number;
  companies: any;
  hwCompanies: any;
  swCompanies: any;
  deleteCompanyId: number;
  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private toasterService: ToasterService,
    private loader: SpinnerService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.loadCompanyData();
  }

  loadCompanyData() {
    this.loader.show();
    this.hwCompanies = [];
    this.swCompanies = [];
    this.companyService.getCompanies().subscribe(
      data => {
        this.loader.hide();
        this.companies = data;
        for(let company of this.companies){
          if(company['partner_type']=='Software'){
            this.swCompanies.push(company);
          }else{
            this.hwCompanies.push(company);
          }
        }
      },
      error => this.loader.hide()
    );
  }

  openDeleteModal(id: number){
      ($('#deleteModal') as any).modal('show');
      this.deleteCompanyId = id;
  }

  deleteCompany() {
    this.companyService.deleteCompany(this.deleteCompanyId).subscribe(data => {
      if (data['success']) {
        this.loadCompanyData();
        this.toasterService.success('User Deleted');
      } else {
        this.toasterService.error('Error', data['error']);
      }
    });
  }

}
