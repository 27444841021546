import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToasterService} from "../../toaster.service";
import {SpinnerService} from "../../spinner/spinner.service";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  activeRequests = 0;
  inactiveRequests = 0;
  companies = 0;
  users = 0;

  constructor(private http: ApiService, private toaster: ToasterService, private loader: SpinnerService) {
  }

  ngOnInit() {
    this.loader.show();
    this.http.get('/api/getHomeData').subscribe(
      data => {
        this.loader.hide();
        if (data['success']) {
          this.inactiveRequests = data['inactiveRequests'];
          this.companies = data['companies'];
          this.activeRequests = this.companies - this.inactiveRequests;
          this.users = data['users'];
        } else {
          this.toaster.error('Error', data['error']);
        }
      },
      error => {
        this.loader.hide();
        this.toaster.error('Error', error);
      }
    );
  }

}
