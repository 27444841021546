import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ApiService} from "../../services/api.service";

@Injectable()
export class UserManagementService {
  constructor(private http: ApiService) {}

  getUsers() {
    return this.http.get('/api/getUsers');
  }

  addUser(user) {
    return this.http.post('/api/addUser', user);
  }

  updateUser(user) {
    return this.http.post('/api/updateUser', user);
  }

  deleteUser(id) {
    return this.http.get('/api/deleteUser/' + id);
  }
}
