import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ApiService} from "../services/api.service";

@Injectable()
export class LicenseService {
  constructor(private http: ApiService) {}

  generateKey(data, isSoftware, id?) {
      return this.http.post('/api/getLicenseKey' + (id ? '/' + id : ''), data);
  }

  viewLicense(id){
      return this.http.get('/api/viewLicense/' + id);
  }

  editKey(data, id){
      return this.http.post('/api/getLicenseKey/' + id, data);
  }

  getCompanyLicenseData() {
    return this.http.get('/api/getCompanyLicenseData');
  }

  getLicenses(id, isAdmin) {
    return this.http.get('/api/getLicenses/' + id);
  }

  limitUpdate(id, date) {
    return this.http.post('/api/limitUpdate/' + id, date);
  }
}
