import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EnvService} from "./env.service";
import {catchError, map} from "rxjs/internal/operators";
import {_throw} from "rxjs-compat/observable/throw";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrl = this.envService.apiUrl;

  constructor(private httpService: HttpClient,
              private envService: EnvService) {
  }

  getRestApiUrl() {
    return this.baseUrl;
  }

  get(url) {
    return this.httpService.get(this.getRestApiUrl() + url).pipe(map((response: any) => response), catchError(error => {
      if(error.status === 401 || error.status === 403) {
          this.httpService.get("/api/logout").subscribe(() => {
              window.location.href = "#/login";
          }, err => {
              window.location.href = "#/login";
          });
      }
      return _throw(error.error);
    }));
  }

  post(url, data) {
    return this.httpService.post(this.getRestApiUrl() + url, data).pipe(map((response: any) => response), catchError(error => {
        if(error.status === 401 || error.status === 403) {
            this.httpService.get("/api/logout").subscribe(() => {
                window.location.href = "#/login";
            }, err => {
                window.location.href = "#/login";
            });
        }
        return _throw(error.error);
    }));
  }

  delete(url) {
    return this.httpService.delete(this.getRestApiUrl() + url);
  }

}
