import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ApiService} from "../services/api.service";

@Injectable()
export class RegistrationService {
  constructor(private http: ApiService) {}

  register(registrationDetail) {
    return this.http.post('/api/register', registrationDetail);
  }
}
