import {Component, OnInit} from '@angular/core';
import {LicenseService} from '../../common/license.service';
import {SpinnerService} from 'src/app/spinner/spinner.service';
import {ToasterService} from "../../toaster.service";

interface LicenseData {
    companyName: string;
    customerName: string;
    macAddress: string;
    deviceCount: number;
    monitoringMode: boolean;
    trialMode: boolean;
    time: number;
    tabs: {
        multiview: boolean;
        videoWall: boolean;
        controlUI: boolean;
        scheduler: boolean;
        analytics: boolean;
        presenter: boolean;
        anyControl: boolean;
    };
}

@Component({
    selector: 'app-licenses',
    templateUrl: './licenses.component.html',
    styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {
    licenseData: LicenseData;
    displayLicenseData: any;
    generate = false;
    licenseKey = '';
    licenseCount = {used: 0, balance: 0};
    companyName = '';
    features = {
        "multiview": 0,
        "videoWall": 0,
        "controlUI": 0,
        "scheduler": 0,
        "analytics": 0,
        "presenter": 0,
        "anyControl": 0
    };
    typeHardware = true;
    companyId: number;

    constructor(private licenseService: LicenseService,
                private loader: SpinnerService,
                private toaster: ToasterService) {
    }

    ngOnInit() {
        this.getLicenseData();
    }

    getLicenseData() {
        this.licenseService.getCompanyLicenseData().subscribe(data => {
            ($('#licenseModal') as any).modal('hide');
            if (data['result'] === 'success') {
                this.companyName = data['data']['name'];
                this.companyId = data['data']['companyId'];
                this.features = JSON.parse(data['data']['features']);
                this.licenseCount.balance = data['data']['balance'];
                this.licenseCount.used = data['data']['used'];
                this.typeHardware = data['data']['type'] === 'Hardware';
                this.reset();
                this.getLicenses();
            }
        });
    }

    getLicenses() {
        this.loader.show();
        this.licenseService.getLicenses(this.companyId, false).subscribe(
            data => {
                this.loader.hide();
                if (data['success']) {
                    this.displayLicenseData = data['licenses'];
                    //console.log("LICES", this.displayLicenseData);
                } else {
                    this.toaster.error('Error', data['error']);
                }
            },
            error => this.loader.hide()
        );
    }

    reset() {
        this.licenseData = {
            companyName: this.companyName,
            customerName: '',
            macAddress: '',
            deviceCount: 2,
            monitoringMode: false,
            trialMode: false,
            tabs: {
                multiview: (!this.typeHardware && this.features['multiview'] == 1) ? true : false,
                videoWall: (!this.typeHardware && this.features['videoWall'] == 1) ? true : false,
                controlUI: (!this.typeHardware && this.features['controlUI'] == 1) ? true : false,
                scheduler: (!this.typeHardware && this.features['scheduler'] == 1) ? true : false,
                analytics: (!this.typeHardware && this.features['analytics'] == 1) ? true : false,
                presenter: (!this.typeHardware && this.features['presenter'] == 1) ? true : false,
                anyControl: (!this.typeHardware && this.features['anyControl'] == 1) ? true : false
            },
            time: 0
        };
    }

    disabledGenerateLicense() {
         return !this.licenseData.macAddress || !this.licenseData.companyName || this.licenseData.deviceCount < 2 ||
             (this.typeHardware && !this.licenseData.monitoringMode && !this.licenseData.tabs.multiview && !this.licenseData.tabs.videoWall && !this.licenseData.tabs.controlUI && !this.licenseData.tabs.scheduler && !this.licenseData.tabs.analytics && !this.licenseData.tabs.presenter && !this.licenseData.tabs.anyControl);
    }

    generateKey() {
        try{
            let regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

            if(regex.test(atob(this.licenseData.macAddress))){
                this.loader.show();
                this.licenseData.time = new Date().getTime();
                this.licenseService
                    .generateKey({data: JSON.stringify(this.licenseData), edit: false}, !this.typeHardware)
                    .subscribe(
                        data => {
                            this.loader.hide();
                            if (data['success']) {
                                ($('#licenseModal') as any).modal('hide');
                                if (data['type'] == 'SOFTWARE') {
                                    this.licenseKey = data['key'];
                                } else {
                                    this.toaster.success('Success', 'License key has been requested.');
                                }
                                this.generate = false;
                                this.getLicenseData();
                            } else {
                                this.toaster.error('Error', data['error']);
                            }
                        },
                        error => this.loader.hide()
                    );
            }else{
                this.toaster.error('Invalid Serial Number');
            }
        }catch  (e){
            this.toaster.error('Invalid Serial Number');
        }
    }

    copyToClipboard(text) {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.toaster.success('Success', 'Copied to clipboard');
    }

    generateLicense() {
        this.licenseKey = '';
        ($('#licenseModal') as any).modal('show');
    }
}
