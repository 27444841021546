import { Component, OnInit } from '@angular/core';
import { CompanyService } from './companies/company.service';
import { UserManagementService } from './user-management.service';
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  providers: [UserManagementService, CompanyService]
})
export class AdminComponent implements OnInit {
  constructor(private auth:AuthService) {}
  isAdmin = false;
  ngOnInit() {
    this.checkAdmin();
  }

  checkAdmin() {
    return this.auth
      .isAdmin()
      .then((isAdmin: boolean) => {
        this.isAdmin = isAdmin;
      });
  }
}
