import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ApiService} from "../../services/api.service";

@Injectable()
export class CompanyService {
  constructor(private httpService: ApiService) {}

  getCompanyData(id: number) {
    return this.httpService.get('/api/getCompanyData/' + id);
  }

  updateCompanyData(companyData) {
    return this.httpService.post('/api/updateCompanyData', companyData);
  }

  getCompanies() {
    return this.httpService.get('/api/getCompanies');
  }

  addLicenseCount(id, num) {
    return this.httpService.get('/api/addLicenseCount/' + id + '/' + num);
  }

  deleteCompany(id) {
    return this.httpService.get('/api/deleteCompany/' + id);
  }
}
