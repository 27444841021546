import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from '../toaster.service';
import { SpinnerService } from '../spinner/spinner.service';
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  emailAddress: string;
  emailSent = false;
  constructor(
    private http: ApiService,
    private toaster: ToasterService,
    private loader: SpinnerService
  ) {}

  ngOnInit() {}

  forgotPassword() {
    this.loader.show();
    this.http.get('/api/forgotPassword/' + this.emailAddress).subscribe(
      data => {
        this.loader.hide();
        if (data['success']) {
          this.emailSent = true;
        } else {
          this.toaster.error('Error', data['error']);
        }
      },
      error => {
        this.loader.hide();
      }
    );
  }
}
