import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RegistrationComponent} from './registration/registration.component';
import {AdminComponent} from './admin/admin.component';
import {RequestsComponent} from './admin/requests/requests.component';
import {CompaniesComponent} from './admin/companies/companies.component';
import {CompanyComponent} from './admin/companies/company/company.component';
import {CustomerComponent} from './customer/customer.component';
import {LoginComponent} from './auth/login/login.component';
import {ManualsComponent} from './customer/manuals/manuals.component';
import {SoftwareComponent} from './customer/software/software.component';
import {UsersComponent} from './customer/users/users.component';
import {LicensesComponent} from './customer/licenses/licenses.component';
import {CustomerGuard} from './auth/customer-guard.service';
import {AdminGuard} from './auth/admin-guard.service';
import {CustomerAdminGuard} from './auth/customer-admin-guard.service';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {ChangePasswordGuard} from './change-password/change-password-guard.service';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {HomeComponent} from './admin/home/home.component';
import {CustomerHomeComponent} from "./customer/customer-home/customer-home.component";
import {StaffUsersComponent} from "./admin/staff-users/staff-users.component";
import {StaffGuard} from "./auth/staff-guard.service";

const routes: Routes = [
  {path: 'registration', component: RegistrationComponent},
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [ChangePasswordGuard]
  },
  {
    path: 'admin',
    canActivate: [StaffGuard],
    component: AdminComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'requests',
        component: RequestsComponent,
        data: {breadcrumb: 'Requests'}
      },
      {
        path: 'users',
        component: StaffUsersComponent,
        canActivate: [AdminGuard],
        data: {breadcrumb: 'Users'}
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        data: {breadcrumb: 'Companies'}
      },
      {
        path: 'companies/:id',
        component: CompanyComponent,
        data: {breadcrumb: 'Company'}
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {breadcrumb: 'Change-password'}
      }
    ],
    data: {breadcrumb: 'Home'}
  },
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [CustomerGuard],
    children: [
      {path: '', component: CustomerHomeComponent},
      {
        path: 'manuals', component: ManualsComponent,
        data: {breadcrumb: 'Manuals'}
      },
      {
        path: 'software', component: SoftwareComponent,
        data: {breadcrumb: 'Software'}
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [CustomerAdminGuard],
        data: {breadcrumb: 'Users'}
      },
      {
        path: 'licenses', component: LicensesComponent,
        data: {breadcrumb: 'Licenses'}
      },
      {
        path: 'change-password', component: ChangePasswordComponent,
        data: {breadcrumb: 'Change-password'}
      }
    ],
    data: {breadcrumb: 'Home'}
  },
  {path: '', component: LoginComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
