import {Component, OnInit, ViewChild} from '@angular/core';
import {RegistrationService} from './registration.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import {ToasterService} from '../toaster.service';
import {NgForm} from '@angular/forms';
import {SpinnerService} from '../spinner/spinner.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    providers: [RegistrationService]
})
export class RegistrationComponent implements OnInit {
    @ViewChild('captcha') captcha: RecaptchaComponent;

    registrationDetail = {
        name: '',
        adminName: '',
        email: '',
        address: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        website: '',
        companyType: '',
        message: '',
        captcha: ''
    };
    passwordNotMatch = false;
    @ViewChild('regForm') form: NgForm;

    captchaResolved = false;

    constructor(private registrationService: RegistrationService,
                private toasterService: ToasterService,
                private loader: SpinnerService,
                private router: Router) {
    }

    ngOnInit() {
    }

    resolved(key) {
        this.registrationDetail.captcha = key;
        if (key) {
            this.captchaResolved = true;
        }
    }

    register() {
        if (this.captchaResolved) {
            this.loader.show();
            this.registrationService.register(this.registrationDetail).subscribe(
                data => {
                    this.loader.hide();
                    this.captcha.reset();
                    this.captchaResolved = false;
                    if (data['success']) {
                        this.toasterService.success(
                            'Registration Successful',
                            'Please wait for activation email.'
                        );
                        this.router.navigate(['/']);
                        this.form.reset();
                    } else {
                        const error = data['error'];
                        let errorMessage = '';
                        Object.keys(error).forEach(field => {
                            errorMessage += field + ' : ';
                            errorMessage += Object.values(error[field]) + ' \n';
                        });
                        this.toasterService.error('Error', errorMessage);
                    }
                },
                error => {
                    this.loader.hide();
                    console.log(error);
                    this.captchaResolved = false;
                    this.toasterService.error('Unsuccessful');
                    this.captcha.reset();
                }
            );
        } else {
            console.log('Captcha not reolved');
        }
    }

    passwordMatch() {
        this.passwordNotMatch = true;
        this.registrationDetail.confirmPassword='';
        window.scrollTo(0, 20);
    }
}
